<template>
  <button type="submit" class="button" :class="cssClass">
    <span v-if="!loading">{{ title }}</span>
    <span class="loading" v-if="loading"></span>
  </button>
</template>
<script>
export default {
  name: "btn",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    cssClass: {
      type: String,
      required: false,
      default: "",
    }
  },
};
</script>
<style scoped>
.button {
  color: #fff;
  border-radius: 4px;
  background-color: #ba1921;
  font-weight: 800;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border: none;
  height: 40px;
  font-size: 16px;
  letter-spacing: .3px;
  cursor: pointer;
}

.loading {
  width: 20px;
  height: 20px;
  border: solid 4px transparent;
  border-top-color: #fff !important;
  border-left-color: #fff !important;
  border-radius: 50%;
  display: block;
  animation: loading-bar-spinner 400ms linear infinite;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>