<template>
 <div id="app" class="loteria">
  <header-template />
  <transition name="fade" mode="out-in">
   <router-view />
  </transition>
  <footer-template />
  <cookies />
  <!-- <div class="lotto-cta">
  <div class="wrapper">
   <p>Loteriada także online! Weź udział w loterii!</p>
   <a
    class="button"
    href="https://www.lotto.pl/?utm_source=loteriada_website&utm_medium=pasek&utm_campaign=loteriada2022"
   >ZAGRAJ TERAZ</a>
  </div>
 </div> -->
 </div>
</template>

<script>
import headerTemplate from "@/components/layout/Header";
import footerTemplate from "@/components/layout/Footer";
import cookies from "@/components/public/cookies";
// import lotto from "@/components/public/lotto";

export default {
 name: "App",
 data: () => ({}),
 components: {
  headerTemplate,
  footerTemplate,
  cookies
  // lotto
 }
};
</script>
<style>
#zowieFloatingButton rect {
    fill:#000 !important;
}
</style>
