<template>
  <div class="spinner" :class="{active: $store.state.loader}">
    <div class="spinner-overlay"></div>
    <div class="spinner-icon"></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Loader",
});
</script>
<style>
.spinner {
  background: black;
  opacity: 0.5;
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
}
.spinner.active {
  display: block;
  z-index: 100;
}
.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
  border-top-color: #00a6e1 !important;
  border-left-color: #00a6e1 !important;
  border-radius: 50%;
  position: relative;
  left: 50%;
  top: 50%;
  animation: loading-bar-spinner 400ms linear infinite;
}

.spinner-overlay {
}
@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
