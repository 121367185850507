<template>
<!--Modal-->
 <div v-if="cookieval === '0'" class="cookies">
  <div class="wrapper">
       <div v-on:click="writeCookie()" class="btn">
    <span>Zgadzam się</span>
   </div>
   <p>
    Nasza strona internetowa może wykorzystywać pliki cookies (tzw. ciasteczka), które służą do identyfikacji Twojej przeglądarki podczas korzystania z naszej strony, abyśmy mogli dopasować wyświetlane treści do Twoich preferencji. Przed wyrażeniem zgody na wykorzystanie plików cookies upewnij się, czy ustawienia Twojego komputera dopuszczają ich akceptację. Możesz ustawić swoją przeglądarkę tak, aby ostrzegała Cię przed akceptacją cookies, lub po prostu je odrzucała, jednakże możesz wówczas nie mieć dostępu do niektórych opcji niniejszego serwisu. Pamiętaj, że w każdej chwili możesz zmienić ustawienia dotyczące plików cookies, a także uzyskać dodatkowe informacje o zasadach przetwarzania Twoich danych osobowych oraz przysługujących Ci prawach w naszej
    <router-link tag="a" to="/polityka-prywatnosci">Polityce Prywatności</router-link>.
   </p>

  </div>
 </div>
</template>

<script>
export default {
 name: "Cookies",
 props: {
  msg: String
 },
 data: () => ({
  cookieval: "0"
 }),
 mounted() {
  this.readCookie("loteriada2022");
 },
 methods: {
  readCookie(name) {
   var value = "; " + document.cookie;
   var parts = value.split("; " + name + "=");
   if (parts.length === 2) {
    var cookieval = parts
     .pop()
     .split(";")
     .shift();
    this.cookieval = cookieval;
   }
  },
  writeCookie() {
   var expires = "";
   var date = new Date();
   date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
   expires = "; expires=" + date.toUTCString();
   document.cookie = "loteriada2022" + "=" + "1" + expires + "; path=/";
   this.cookieval = "1";
  }
 }
};
</script>
<style>
.cookies { padding: 20px; position: fixed; width: 100%; text-align: center; background: #4d4d5c; bottom: 0; left: 0; color: #ffffff; font-size: 11px; z-index: 100; }
.cookies .wrapper { display: flex; flex-wrap: wrap; align-items: center; }
.cookies p { padding: 20px; padding-right: 80px; width: calc(100% - 80px); line-height: 16px; text-align: left; }
.cookies .btn { 
    background: #e2215a;
    color: #fff;
    display: inline-block;
    padding: 1em 0.5em;
    cursor: pointer;
    font-weight: 700;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.cookies a { color: #00a6e1; }

@media all and (max-width:60em) {
    .cookies p {
        padding-right: 0px;
    }
}

</style>