<template>
  <div class="home">
    <div class="hero" ref="prizesRef">
      <img src="assets/img/hero2.png" />
    </div>
    <div class="vimeo">
      <div class="wrapper">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/iesXLVYZJNU?si=H9ti6zcL1eva6TUg"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
      </div>
    </div>
    <div class="rules">
      <div class="section-title" ref="rulesRef">
        <span class="left"></span>
        <span class="text">Zasady dla punktów sprzedaży</span>
        <span class="right"></span>
      </div>
      <ul class="grid">
        <li>
          <div class="ribbon">
            <span>1</span>
          </div>
          <h4 class="coin"></h4>
          <div class="box">
            <p><strong>Kup dowolne produkty LOTTO<br />za min. 6&nbsp;zł&nbsp;i&nbsp;odbierz kupon
                promocyjny</strong>.
            </p>
          </div>
        </li>
        <li>
          <div class="ribbon"><span>2</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>
              Wybierz Lotto lub Lotto Plus za<br />min. 6&nbsp;zł&nbsp;na jednym kuponie i&nbsp;graj<br />o&nbsp;jeden
              z&nbsp;MILIONA
              talonów Lotto.
            </p>
          </div>
        </li>
        <li>
          <div class="ribbon"><span>3</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>Utwórz konto do 1 stycznia 2024 r. oraz zarejestruj kody do 4 stycznia 2024 r.
              i&nbsp;weź udział
              w&nbsp;losowaniach
              nagród.</p>
          </div>
        </li>
        <li>
          <div class="ribbon"><span>4</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>Jeśli wygrałeś wyślij oświadczenie (w&nbsp;ciągu 7 dni od otrzymania maila
              z&nbsp;informacją o wygranej).
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="rules">
      <div class="section-title" ref="lottoOnlineRef">
        <span class="left"></span>
        <span class="text">Zasady lotto.pl/aplikacja LOTTO</span>
        <span class="right"></span>
      </div>
      <ul class="grid">
        <li>
          <div class="ribbon"><span>1</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>Kup dowolne produkty LOTTO<br />za min. 6&nbsp;zł w trakcie jednego logowania.</p>
          </div>
        </li>
        <li>
          <div class="ribbon"><span>2</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>W dniu zakupu utwórz konto na lotteriaswiateczna.pl podając e-mail wskazany na
              lotto.pl/aplikacji LOTTO.</p>
          </div>
        </li>
        <li>
          <div class="ribbon"><span>3</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>Kod promocyjny otrzymasz<br />w&nbsp;ciągu 2&nbsp;dni roboczych na konto.<br />
              Zarejestruj kod do 4&nbsp;stycznia 2024 r.<br />i&nbsp;weź udział w&nbsp;losowaniach nagród.
            </p>
          </div>
        </li>
        <li>
          <div class="ribbon"><span>4</span></div>
          <h4 class="coin"></h4>
          <div class="box">
            <p>
              Jeśli wygrałeś wyślij oświadczenie (w&nbsp;ciągu 7 dni od otrzymania maila z&nbsp;informacją
              o&nbsp;wygranej).
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="draw-dates">
      <div class="section-title" ref="drawDatesRef">
        <span class="left"></span>
        <span class="text">Terminy losowań</span>
        <span class="right"></span>
      </div>
      <div class="page-content">
        <div class="table">
          <div class="head">
            <div class="item">
              <div class="lp">Lp.</div>
              <div class="date">Termin losowania</div>
              <div class="range">
                Kody promocyjne zarejestrowane w okresie
              </div>
            </div>
          </div>
          <div class="body">
            <div class="item">
              <div class="lp">
                Losowanie 1
              </div>
              <div class="date">
                <span class="mob data">Termin losowania</span>
                07.12.2023 r.
              </div>
              <div class="range">
                <span class="mob range">Kody promocyjne zarejestrowane w&nbsp;okresie</span>
                od 27.11.2023 r., godz. 06:30:00 do&nbsp;04.12.2023 r., godz. 23:59:59
              </div>
            </div>
            <div class="item">
              <div class="lp">Losowanie 2</div>
              <div class="date"><span class="mob data">Termin losowania</span>
                14.12.2023 r.</div>
              <div class="range">
                <span class="mob range">Kody promocyjne zarejestrowane w&nbsp;okresie</span>
                od 5.12.2023 r., godz. 00:00:00 do&nbsp;11.12.2023 r., godz. 23:59:59
              </div>
            </div>
            <div class="item">
              <div class="lp">Losowanie 3</div>
              <div class="date"><span class="mob data">Termin losowania</span>
                21.12.2023 r.</div>
              <div class="range">
                <span class="mob range">Kody promocyjne zarejestrowane w&nbsp;okresie</span>
                od 12.12.2023 r., godz. 00:00:00 do&nbsp;18.12.2023 r., godz. 23:59:59
              </div>
            </div>
            <div class="item">
              <div class="lp">Losowanie 4</div>
              <div class="date"><span class="mob data">Termin losowania</span>
                09.01.2024 r.</div>
              <div class="range">
                <span class="mob range">Kody promocyjne zarejestrowane w&nbsp;okresie</span>
                od 19.12.2023 r., godz. 00:00:00 do&nbsp;04.01.2024 r., godz. 23:59:59
              </div>
            </div>
            <div class="item item__final">
              <div class="lp">Losowanie finałowe<br />100 000 zł</div>
              <div class="date"><span class="mob data">Termin losowania</span>
                09.01.2024 r.</div>
              <div class="range">
                <span class="mob range">Kody promocyjne zarejestrowane w&nbsp;okresie</span>
                od 27.11.2023 r., godz. 06:30:00 do&nbsp;04.01.2024 r., godz. 23:59:59
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="winners">
      <div class="section-title" ref="winersRef">
        <span class="left"></span>
        <span class="text">Lista zwycięzców</span>
        <span class="right"></span>
      </div>
      <div class="page-content">
        <div class="win-info">
          <p>
            Wylosowane kody promocyjne wraz z przypisaną do nich nagrodą publikowane są w ciągu 2 dni roboczych licząc od
            dnia następnego po losowaniu.<br />
            Laureaci informowani są o wygranej w ciągu 2 dni roboczych licząc od dnia następnego po losowaniu.<br />
            Dane Zwycięzców w postaci imienia oraz pierwszej litery nazwiska uzupełniane są na bieżąco (po zakończeniu
            procedury weryfikacji zgłoszeń), z zastrzeżeniem, że pełna lista zwycięzców jest publikowana nie później niż
            do 31.03.2024 r.
          </p>
          <div class="live__search">
            <label>Wyszukaj po kodzie promocyjnym spośród kodów wygrywających</label>
            <input type="text" placeholder="wprowadź kod promocyjny" maxlength="13" v-model="searchTerm"
              v-mask="'NNNN-NNN-NNNN'" />
            <a v-on:click="filterWinners($event)" class="button">Wyszukaj</a>
          </div>
        </div>

        <h3>
          <strong>Losowanie z dnia 7 grudnia 2023 r.</strong>
          <strong>(kody zarejestrowane od 27.11.2023 r. godz. 06:30:00 do 04.12.2023 r. godz. 23:59:59)</strong>
        </h3>
        <div class="draw__item_info">
          <ul class="draw__item_prizes">
            <li>
              <div class="description"><strong>1 x</strong> 25 000 zł</div>
            </li>
            <li>
              <div class="description"><strong>12 x</strong> 5 000 zł</div>
            </li>
            <li>
              <div class="description"><strong>125 x</strong> 500 zł</div>
            </li>
          </ul>
        </div>
        <div class="draw">
          <drawItem :draw="drawFiltered" :drawName="'Draw1'" />
        </div>

        <h3>
          <strong>Losowanie z dnia 14 grudnia 2023 r.</strong>
          <strong>(kody zarejestrowane od 5.12.2023 r., godz. 00:00:00 do 11.12.2023 r., godz. 23:59:59)</strong>
        </h3>
        <div class="draw">
          <div class="draw__item_info">
            <ul class="draw__item_prizes">
              <li>
                <div class="description"><strong>1 x</strong> 25 000 zł</div>
              </li>
              <li>
                <div class="description"><strong>13 x</strong> 5 000 zł</div>
              </li>
              <li>
                <div class="description"><strong>125 x</strong> 500 zł</div>
              </li>
            </ul>
          </div>
          <drawItem :draw="drawFiltered" :drawName="'Draw2'" />
        </div>

        <h3>
          <strong>Losowanie z dnia 21 grudnia 2023 r.</strong>
          <strong>(kody zarejestrowane od 12.12.2023 r., godz. 00:00:00 do 18.12.2023 r., godz. 23:59:59)</strong>
        </h3>
        <div class="draw">
          <div class="draw__item_info">
            <ul class="draw__item_prizes">
              <li>
                <div class="description"><strong>1 x</strong> 25 000 zł</div>
              </li>
              <li>
                <div class="description"><strong>12 x</strong> 5 000 zł</div>
              </li>
              <li>
                <div class="description"><strong>125 x</strong> 500 zł</div>
              </li>
            </ul>
          </div>
          <drawItem :draw="drawFiltered" :drawName="'Draw3'" />
        </div>
        <h3>
          <strong>Losowanie z dnia 9 stycznia 2024 r. </strong>
          <strong>(kody zarejestrowane od 19.12.2023 r., godz. 00:00:00 do 04.01.2024 r., godz. 23:59:59)</strong>
        </h3>
        <div class="draw">
          <div class="draw__item_info">
            <ul class="draw__item_prizes">
              <li>
                <div class="description"><strong>1 x</strong> 25 000 zł</div>
              </li>
              <li>
                <div class="description"><strong>13 x</strong> 5 000 zł</div>
              </li>
              <li>
                <div class="description"><strong>125 x</strong> 500 zł</div>
              </li>
            </ul>
          </div>
          <drawItem :draw="drawFiltered" :drawName="'Draw4'" />
        </div>

        <h3>
          <strong>Losowanie finałowe z dnia 9 stycznia 2024 r. </strong>
          <strong>(kody zarejestrowane od 27.11.2023 r. godz. 06:30:00 do 04.01.2024 r. godz. 23:59:59)</strong>
        </h3>
        <div class="draw">
          <div class="draw__item_info">
            <ul class="draw__item_prizes draw_main">
              <li>
                <div class="description"><strong>1 x</strong> 100 000 zł</div>
              </li>
            </ul>
          </div>
          <drawItem :draw="drawFiltered" :drawName="'Draw5'" />
        </div>
      </div>
    </div>
    <div class="faq">
      <div class="section-title" ref="faqRef">
        <span class="left"></span>
        <span class="text">Najczęściej zadawane pytania</span>
        <span class="right"></span>
      </div>
      <div class="page-content">
        <h3 v-on:click="showFaqMainItem(0)">
          <span>WARUNKI UCZESTNICTWA W&nbsp;LOTERII</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>1. W&nbsp;jakim okresie trwa loteria?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Sprzedaż promocyjna trwa od 27 listopada 2023 r. od godz. 6:30:00 do 31 grudnia 2023 r. do godz. 23:54:00,
                z zastrzeżeniem, że liczba talonów jest ograniczona i wynosi 1 000 000 sztuk.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>2. Kto może wziąć udział w&nbsp;loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Uczestnikami loterii mogą być wyłącznie osoby fizyczne, które:
              <ol class="list">
                <li>1) ukończyły 18 lat,</li>
                <li>2) posiadają pełną zdolność do czynności prawnych,</li>
                <li>3) zamieszkują na terytorium Rzeczypospolitej Polskiej,</li>
                <li>4) dokonały zakupu produktów LOTTO za min. 6 zł oraz otrzymały kupon promocyjny lub kod w wersji
                  elektronicznej,</li>
                <li>5) oraz spełniają pozostałe wymagania opisane w regulaminie loterii.</li>
              </ol>
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>3. Kto NIE może wziąć udziału w loterii? </span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                W loterii nie mogą brać udziału:
              <ol>
                <li>1) członkowie organów (Zgromadzenia Wspólników, Rady Nadzorczej, Zarządu) spółek: Totalizator Sportowy
                  Sp. z o.o. i IGT Polska Sp. z o.o.,</li>
                <li>2) pracownicy Totalizatora Sportowego Sp. z o.o., współpracownicy Totalizatora Sportowego Sp. z o.o.
                  (bez
                  względu na formę współpracy) oraz kolektorzy,</li>
                <li>3) członkowie najbliższych rodzin wszystkich ww. osób (wstępnych, zstępnych, rodzeństwo, małżonków,
                  małżonków i zstępnych rodzeństwa, rodziców i rodzeństwo małżonków oraz osoby pozostające w stosunku
                  przysposobienia).</li>
              </ol>
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>4. W&nbsp;jaki sposób można przystąpić do loterii w punktach sprzedaży LOTTO?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Aby przystąpić do loterii należy:</p>
              <ul>
                <li>
                  1) Kupić dowolny produkt LOTTO za min. 6 zł i odebrać kupon promocyjny.
                </li>
                <li>
                  2) Wybrać grę Lotto lub Lotto Plus za min. 6 zł na jednym kuponie, aby dodatkowo otrzymać szansę na
                  jeden z MILIONA talonów Lotto.
                </li>
                <li>
                  3) Założyć i aktywować konto na lotteriaswiateczna.pl do 1 stycznia 2024 r.
                </li>
                <li>
                  4) Zarejestrować otrzymany kod promocyjny na lotteriaswiateczna.pl do 4 stycznia 2024 r.
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>5. Jak przystąpić do loterii przez lotto.pl i aplikację LOTTO?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Aby przystąpić do loterii należy:</p>
              <ul>
                <li>
                  1) Kupić dowolny produkt LOTTO lub założyć subskrypcję za min. 6 zł podczas jednego logowania.
                </li>
                <li>
                  2) W dniu zakupu założyć i aktywować konto na lotteriaswiateczna.pl podając adres e-mail używany na
                  lotto.pl lub w aplikacji LOTTO.
                </li>
                <li>
                  3) Zarejestrować otrzymany (w ciągu 2 dni roboczych) na konto kod promocyjny na lotteriaswiateczna.pl do
                  4 stycznia 2024 r.
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>6. Czym jest kod promocyjny?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Jest unikalnym, alfanumerycznym, składającym się z 13 znaków kodem, który należy zarejestrować na
                lotteriaswiateczna.pl aby wziąć udział w losowaniach nagród.</p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>7. Co oznacza „Sesja” na lotto.pl lub w aplikacji LOTTO? </span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Sesja logowania to czas liczony od momentu zalogowania się na indywidualnym koncie na lotto.pl/aplikacji
                LOTTO do momentu wylogowania się przy użyciu przycisku “Wyloguj się”/„Wyloguj”. Za zakupy dokonane podczas
                jednej sesji można otrzymać wyłącznie jeden kod promocyjny.</p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>8. Ile otrzymam kodów za subskrypcję?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Za każdą założoną subskrypcję otrzymasz wyłącznie jeden kod promocyjny, niezależnie od wartości oraz
                czasu trwania tej subskrypcji (w ciągu 2 dni roboczych po pobraniu pierwszej opłaty).</p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>9. Czy e-maile z kodami promocyjnymi zobaczę w folderze „Wiadomości” na lottto.pl/aplikacji
                LOTTO?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Nie, kody promocyjne zostaną przyznane bezpośrednio na utworzone wcześniej konto na
                lotteriaswiateczna.pl.</p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>10. Dokonałem zakupu w punkie sprzedaży LOTTO i nie otrzymałem kuponu promocyjnego. Co zrobić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Kupon promocyjny drukuje się zawsze po zamknięciu transakcji spełniającej warunki loterii jako osobny
                wydruk.<br />
                W przypadku nieotrzymania kuponu promocyjnego od kolektora uczestnik powinien zażądać jego wydania. Po
                opuszczeniu kolektury nie przysługuje prawo żądania wydania kuponu promocyjnego.<br />
                Uczestnik o zaistniałej sytuacji i braku wydania kuponu promocyjnego powinien niezwłocznie poinformować
                TS, nie później jednak niż w ciągu 24 godzin od zdarzenia wysyłając wiadomość na adres:
                <a href="mailto:loterie@totalizator.pl">loterie@totalizator.pl</a>.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>11. Dokonałem zakupu na terminalu samoobsługowym i nie otrzymałem kuponu promocyjnego. Co
                zrobić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Na terminalu samoobsługowym kupon promocyjny wydawany jest po wykorzystaniu przez uczestnika całej kwoty
                wprowadzonej do terminala samoobsługowego i zakończeniu transakcji. W przypadku spełnienia warunku i
                nieotrzymania kuponu promocyjnego uczestnik powinien o zaistniałej sytuacji niezwłocznie poinformować TS,
                nie później jednak niż w ciągu 24 godzin od zdarzenia, wysyłając wiadomość na adres <a
                  href="mailto:loterie@totalizator.pl">loterie@totalizator.pl</a>.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>12. Nie chcę otrzymywać informacji marketingowych. Co zrobić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Zgodę na otrzymywanie treści marketingowych od Totalizatora Sportowego możesz wycofać w dowolnym
                momencie. Cofnięcie zgody następuje poprzez wysłanie żądania przez uczestnika loterii na adres e-mail:
                iod@totalizator.pl lub korzystając z linku zmieszczonego w wiadomości e-mail o treści marketingowej. </p>
            </div>
          </div>
        </div>

        <h3 v-on:click="showFaqMainItem(1)">
          <span>MOJE KONTO</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>1. Czy aby wziąć udział w loterii koniecznie jest założenie konta na stronie loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Aby wziąć udział w „Lotterii Świątecznej” uczestnik zakłada indywidualne konto na stronie loterii. Do
                udziału niezbędne jest wskazanie imienia, nazwiska, numeru telefonu, a także adresu e-mail (w przypadku
                zakupów dokonanych na lotto.pl lub aplikacji LOTTO konieczne jest podane takiego samego adresu e-mail)
                oraz wyrażenie obowiązkowych zgód i oświadczeń.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>2. Jak założyć konto?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Aby założyć konto należy skorzystać z zakładki „Zarejestruj” dostępnej na stronie lotteriaswiateczna.pl.
                Podczas rejestracji należy:
              </p>
              <ul>
                <li>a) podać: imię, nazwisko, adres e-mail, telefon,</li>
                <li>b) ustanowić login oraz hasło (minimum 8 znaków, w tym 1 duża litera i 1 cyfra),</li>
                <li>c) zaznaczyć zgody i oświadczenia niezbędne do udziału w loterii,</li>
                <li>d) potwierdzić proces rejestracji poprzez link aktywacyjny, który wysyłany jest w ciągu 24 godzin na
                  podany podczas rejestracji adres e-mail.</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>3. Nie otrzymałem linku aktywacyjnego, co zrobić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Należy sprawdzić czy link nie znajduje się w folderze spam, oferty lub pozostałych folderach w skrzynce
                pocztowej. Prawdopodobne jest, że wiadomość z linkiem trafiła właśnie tam. Jeżeli nadal nie możesz znaleźć
                wiadomości z linkiem aktywacyjnym, skontaktuj się z naszą Infolinią pod nr telefonu: 459-59-59-00 lub
                napisz na adres email: <a href="mailto:loteria.promocyjna@lotto.pl">loteria.promocyjna@lotto.pl</a> z
                prośbą o ponowne przesłanie linku aktywacyjnego.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>4. Popełniłem błąd podczas wprowadzania swoich danych, czy mogę je zaktualizować?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Aktualizacji danych można dokonać wysyłając wiadomość e-mail (z adresu podanego podczas zakładania konta)
                na adres: <a href="mailto:loteria.promocyjna@lotto.pl">loteria.promocyjna@lotto.pl</a>.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>5. Zapomniałem hasła do konta. Czy mogę je odzyskać/ zmienić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                W każdym momencie istnieje możliwość skorzystania z opcji „Zmień
                hasło” dostępnej w zakładce „Zaloguj”.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>6. Chcę wycofać zgodę na przetwarzanie danych w loterii. Co zrobić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Podanie danych jest dobrowolne, jednak niezbędne do udziału w loterii. Niepodanie lub wycofanie zgody na
                przetwarzanie danych osobowych w niezbędnym zakresie skutkuje niemożnością udziału w Loterii.
              </p>
              <p>Zgodę na przetwarzanie danych osobowych w loterii można wycofać w dowolnym momencie wysyłając żądanie na
                adres e-mail: iod@totalizator.pl. </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>7. Czy mogę sam usunąć swoje konto? Jak to zrobić?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Tak, istnieje możliwość samodzielnego usunięcia konta, jeśli nie został na nim zarejestrowany żaden kod
                promocyjny. Samodzielnego usunięcia konta można dokonać po zalogowaniu się, poprzez wybór przycisku „Usuń
                konto”.
                Jeśli na koncie istnieją zarejestrowane kody promocyjne taką prośbę należy przesłać na adres e-mail:
                iod@totalizator.pl
              </p>
            </div>
          </div>
        </div>

        <h3 v-on:click="showFaqMainItem(2)">
          <span>NAGRODY</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>1. Jakie nagrody są do wygrania w&nbsp;loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <ul>
                <li>
                  1) Nagroda główna (I stopnia) - w&nbsp;postaci nagrody pieniężnej w&nbsp;wysokości 100 000 zł,
                  przekazywanej
                  przelewem bankowym przez TS na wskazany przez zwycięzcę rachunek bankowy, wraz z&nbsp;dodatkową nagrodą
                  pieniężną przeznaczoną na zapłatę podatku dochodowego od nagrody w&nbsp;wysokości 11 111 zł. Organizator
                  przewidział 1 nagrodę I stopnia w&nbsp;loterii.
                </li>
                <li>
                  2) Nagroda II stopnia - w&nbsp;postaci nagrody pieniężnej w&nbsp;wysokości 25 000 zł, przekazywanej
                  przelewem
                  bankowym przez TS na wskazany przez zwycięzcę rachunek bankowy, wraz z&nbsp;dodatkową nagrodą pieniężną
                  przeznaczoną na zapłatę podatku dochodowego od nagrody w wysokości 2 778 zł. Organizator przewidział 4
                  nagrody II stopnia w&nbsp;loterii.
                </li>
                <li>
                  3) Nagroda III stopnia - w&nbsp;postaci nagrody pieniężnej w&nbsp;wysokości 5 000 zł, przekazywanej
                  przelewem
                  bankowym przez TS na wskazany przez zwycięzcę rachunek bankowy, wraz z&nbsp;dodatkową nagrodą pieniężną
                  przeznaczoną na zapłatę podatku dochodowego od nagrody w wysokości 556 zł. Organizator przewidział 50
                  nagród III stopnia w&nbsp;loterii.
                </li>
                <li>
                  4) Nagroda IV stopnia - w&nbsp;postaci nagrody pieniężnej w wysokości 500 zł przekazywanej przelewem
                  bankowym
                  przez TS na wskazany przez zwycięzcę rachunek bankowy. Organizator przewidział 500 nagród IV stopnia
                  w&nbsp;loterii.
                </li>
                <li>
                  5) Nagroda V stopnia - w&nbsp;postaci talonu uprawniającego do wniesienia opłaty przez terminal za jeden
                  zakład prosty gry liczbowej Lotto o&nbsp;wartości 3 zł brutto. Organizator przewidział 1 000 000 sztuk
                  nagród
                  V stopnia w&nbsp;loterii.
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>2. Kto jest płatnikiem podatku od nagrody?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Nagrody otrzymane w loterii są opodatkowane zgodnie z obowiązującym prawem podatkowym, tj. zgodnie z
                ustawą z dnia 26 lipca 1991 r. o podatku dochodowym od osób fizycznych. Oznacza to, że TS pobiera od
                nagrody o wartości powyżej 2 280 zł kwotę w wysokości 10% wartości tej nagrody na poczet należnego podatku
                od nagrody.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>3. Kiedy mam szansę otrzymać talon?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Aby mieć szansę na otrzymanie talonu na zakład Lotto o wartości 3 zł należy zakupić w sieci naziemnej grę
                Lotto lub Lotto Plus za min. 6 zł na jednym kuponie z zastrzeżeniem, że liczba talonów jest ograniczona i
                wynosi 1 000 000 sztuk.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>4. Jak i do kiedy mogę zrealizować nagrodę w postaci talonu?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Talon można zrealizować od razu po jego otrzymaniu. Realizacja talonu możliwa jest w punktach sprzedaży
                LOTTO w dniach od 27 listopada 2023 r. od godz. 06:30:00 do 4 stycznia 2024 r. do godz. 23:54:00, poprzez
                wniesienie nim opłaty za jeden zakład prosty gry liczbowej Lotto o wartości 3 zł metodą chybił trafił.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>5. Czy podczas wymiany talonu na zakład Lotto mogę dokupić opcję Plus/większą ilość zakładów lub
                losowań?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Podczas realizacji talonu istnieje możliwość dokupienia do zakładu Lotto opcji Plus (wyłącznie metodą
                chybił trafił i tylko na najbliższe losowanie) za dopłatą wynoszącą 1,00 zł. Nie ma natomiast możliwości
                dokupienia większej ilości zakładów lub losowań.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>6. Czy jeśli podczas wymiany talonu dokupię kolejny produkt LOTTO na oddzielnym kuponie osiągając
                łączną kwotę zakupu 6 zł, to otrzymam kolejny kupon z kodem promocyjnym i szansę na wygranie kolejnego
                talonu?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Realizacja talonu zaliczana jest w systemie jako sprzedaż. Jeśli do otrzymanego (w wyniku realizacji
                talonu) kuponu Lotto zostanie dokupiony dowolny produkt LOTTO i wartość koszyka osiągnie wartość min. 6 zł
                to kolejny kupon promocyjny zostanie wydrukowany. </p>
              <p>
                Talon drukowany jest wyłącznie do kuponów gry Lotto lub Lotto Plus o wartości min. 6 zł na jednym kuponie,
                więc w przypadku zakupu o niższej wartości, uczestnik nie będzie miał szansy na wygranie talonu.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>7. Otrzymałem informację o wygranej, co powinienem zrobić, aby odebrać nagrodę?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                W przypadku uzyskania informacji o wygranej należy wypełnić i wysłać za pomocą przycisku „Wyślij” właściwe
                oświadczenie udostępniane poprzez link w wiadomości e - mail z potwierdzeniem wygranej.
              </p>
              <p>
                <strong>Dokumenty w formie elektronicznej należy dostarczyć do TS, tj. wypełnić i wysłać w ciągu 7 dni
                  kalendarzowych od dnia otrzymania informacji o wygranej.</strong>
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>8. Czy mogę przesłać dokumenty w wersji papierowej?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Organizator dopuszcza możliwość przesłania dokumentów w wersji papierowej. Właściwe oświadczenie wraz z
                oryginałem kuponu promocyjnego należy wypełnić
                i dostarczyć <strong>w ciągu 7 dni kalendarzowych od dnia otrzymania informacji o wygranej</strong> drogą
                pocztową (list
                polecony lub inna rejestrowana korespondencja), przesyłką kurierską, osobiście lub przez osobę
                upoważnioną, do siedziby organizatora (w dni robocze, od poniedziałku do piątku w godzinach 09:00 – 16:00)
                na adres: Totalizator Sportowy Sp. z o.o., ul. Targowa 25, 03-728 Warszawa, z dopiskiem: loteria „Lotteria
                Świąteczna”.

              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>9. W jaki sposób i do kiedy zostaną przekazane zwycięzcom nagrody?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Nagrody wysyłane są przelewem na rachunek bankowy, wskazany przez zwycięzcę w oświadczeniu najpóźniej do
                dnia 31 marca 2024 r.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>10. Jakie wymagania powinien spełnić rachunek bankowy, na który zostanie przekazana nagroda?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Numer rachunku bankowego powinien składać się z 26 cyfr, a konto bankowe, którego zwycięzca jest
                właścicielem powinno zostać założone i być prowadzone na terytorium Rzeczypospolitej Polskiej.
              </p>
            </div>
          </div>
        </div>

        <h3 v-on:click="showFaqMainItem(3)">
          <span>LOSOWANIA NAGRÓD</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>1. Jak i&nbsp;gdzie odbywają się losowania?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Losowania przeprowadzane są w siedzibie organizatora w Warszawie przy ul. Targowej 25 w obecności Komisji
                Nadzoru Loterii. Losowania nie są transmitowane w mediach. Losowanie czasów otwarcia bramek czasowych dla
                nagród V stopnia odbyło się 24 listopada 2023 r., natomiast losowania nagród I, II, III i IV stopnia
                odbywają się według następującego schematu:
              </p>
              <ul>
                <li>
                  1) Dla każdego prawidłowo zarejestrowanego kodu promocyjnego biorącego udział w danym losowaniu,
                  przydzielany jest unikalny numer ID, nadawany w kolejności czasu wpłynięcia zgłoszenia na serwer.
                </li>
                <li>
                  2) Losowanie odbywa się poprzez wylosowanie zwycięskiego numeru ID.
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>2. Co to jest bramka czasowa?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Bramka czasowa jest to czasookres (data i godzina) po otwarciu którego pierwszych 200 transakcji zakupu
                gry liczbowej Lotto lub Lotto z opcją Plus o wartości minimum 6 zł na jednym kuponie w sieci sprzedaży
                naziemnej TS otrzymuje dodatkowy wydruk w postaci talonu uprawniającego do wymiany na jeden zakład prosty
                gry liczbowej Lotto. Daty i godziny otwarć bramek nie są podawane do wiadomości publicznej.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>3. Kiedy następuje przypisanie nagrody V stopnia (talonu) do bramki czasowej?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Bramki czasowe otwierane są w okresie promocyjnym w godzinach od 6:30:00 do 21:00:00 . Przypisanie talonu
                do otwartej bramki odbywa się zgodnie z kolejnością rejestracji transakcji w systemie sprzedażowym TS w
                sieci naziemnej. Przypisanie talonu do transakcji zakupu gry Lotto lub Lotto z opcją Plus następuje w
                momencie, gdy transakcja dokonywana jest jako jedna z 200 pierwszych transakcji, po upływie daty i godziny
                wylosowanej bramki czasowej. W przypadku, gdy żadna transakcja zakupu gry Lotto spełniająca warunek nie
                jest zarejestrowana pomiędzy dwoma bramkami lub w przypadku niewydania wszystkich talonów przewidzianych w
                bramce czasowej, nieprzyznane nagrody wydawane są w pierwszej kolejności do transakcji dokonanych w
                kolejnej bramce czasowej w tym samym lub następnym dniu (w zależności od tego, kiedy następuje otwarcie
                kolejnej bramki czasowej).
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showItem($event)">
              <span>4. Ile bramek czasowych jest przewidzianych w loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Organizator przewidział otwarcie 5 000 bramek czasowych. Do każdej bramki przypisanych jest 200 nagród V
                stopnia (talonów).
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>5. Kiedy odbywają się losowania nagród I, II, III i IV stopnia?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <table class="table">
                <thead>
                  <tr>
                    <td>Lp.</td>
                    <td>Data losowania</td>
                    <td>Zarejestrowane kody biorące udział w&nbsp;losowaniu</td>
                    <td>Losowane nagrody</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>7 grudnia 2023 r.</td>
                    <td>
                      od dnia 27.11.2023 od godz. 06:30:00<br />
                      do dnia 04.12.2023 do godz. 23:59:59
                    </td>
                    <td>
                      a) Nagroda II st.- 1 szt.<br />
                      b) Nagroda III st.- 12 szt.<br />
                      c) Nagroda IV st.- 125 szt.<br />
                    </td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>14 grudnia 2023 r.</td>
                    <td>
                      od dnia 05.12.2023 od godz. 00:00:00<br />
                      do dnia 11.12.2023 do godz. 23:59:59
                    </td>
                    <td>
                      a) Nagroda II st.- 1 szt.<br />
                      b) Nagroda III st.- 13 szt.<br />
                      c) Nagroda IV st.- 125 szt.<br />
                    </td>
                  </tr>
                  <tr>
                    <td>3.</td>
                    <td>21 grudnia 2023 r.</td>
                    <td>
                      od dnia 11.12.2023 od godz. 00:00:00<br />
                      do dnia 18.12.2023 do godz. 23:59:59
                    </td>
                    <td>
                      a) Nagroda II st.- 1 szt.<br />
                      b) Nagroda III st.- 12 szt.<br />
                      c) Nagroda IV st.- 125 szt.<br />
                    </td>
                  </tr>
                  <tr>

                    <td>4)</td>
                    <td>9 stycznia 2024 r.</td>
                    <td>
                      od dnia 19.12.2023 od godz. 00:00:00<br />
                      do dnia 04.01.2024 do godz. 23:59:59
                    </td>
                    <td>
                      a) Nagroda II st.- 1 szt.<br />
                      b) Nagroda III st.- 13 szt.<br />
                      c) Nagroda IV st.- 125 szt.<br />
                    </td>
                  </tr>
                  <tr>
                    <td>5)</td>
                    <td>9 stycznia 2024 r.</td>
                    <td>
                      od dnia 27.11.2023 od godz. 06:30:00<br />
                      do dnia 04.01.2024 do godz. 23:59:59
                    </td>
                    <td>
                      a) Nagroda I st. - 1 szt.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                W losowaniach o których mowa powyżej, do każdej z nagród I, II i III stopnia losowane jest jedno
                zgłoszenie rezerwowe. W przypadku, gdy laureat zgłoszenia zwycięskiego straci prawo do nagrody, nagroda
                przypada laureatowi, którego zgłoszenie zostało wylosowane jako rezerwowe. Do laureata rezerwowego stosuje
                się analogiczne postanowienia jak do laureata, który został wylosowany jako pierwszy.
                Nagrody IV stopnia są przydzielane jednokrotnie i nie biorą udziału w losowaniu rezerwowym.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>6. W jaki sposób dowiem się o wygranej?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Laureaci nagród I, II i III stopnia informowani są o wygranej przez:</p>
              <ul>
                <li>
                  1) Bezpłatny SMS wysyłany z numeru oznaczonego jako „LOTTERIA” zgodnie z danymi wskazanymi przez
                  laureata podczas rejestracji konta z informacją, że w dniu dzisiejszym konsultant TS podejmie
                  telefoniczną próbę kontaktu.
                </li>
                <li>
                  2) Telefonicznie na numer telefonu podany podczas rejestracji konta. TS dokonuje łącznie 3 próby
                  kontaktu telefonicznego z laureatem w ciągu jednego dnia roboczego.
                </li>
                <li>
                  3) Wiadomość e-mail wysyłaną z adresu <a
                    href="mailto:loteria.promocyjna@lotto.pl">loteria.promocyjna@lotto.pl</a> lub
                  loteria.promocyjna@totalizator.pl
                  na adres e-mail laureata podany podczas zakładania konta.
                </li>
              </ul>
              <p>Laureaci nagród IV stopnia informowani są o wygranej przez:</p>
              <ul>
                <li>
                  1) Bezpłatny SMS wysyłany z numeru oznaczonego jako „LOTTERIA” na numer telefonu podany podczas
                  rejestracji konta, zawierający informację o wygranej nagrodzie.
                </li>
                <li>
                  2) Wiadomość e-mail wysyłaną z adresu <a
                    href="mailto:loteria.promocyjna@lotto.pl">loteria.promocyjna@lotto.pl</a> lub
                  loteria.promocyjna@totalizator.pl
                  na adres e-mail laureata podany podczas zakładania konta.
                </li>
              </ul>
              <p>Laureaci informowani są o wygranej w ciągu 2 dni roboczych, licząc od dnia następnego po losowaniu.
                Przyjmuje się, iż datą otrzymania informacji o wygranej przez laureata jest data umieszczenia wiadomości
                e-mail przez TS w systemie teleinformatycznym.</p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>7. W ilu losowaniach weźmie udział zarejestrowany przeze mnie kod promocyjny?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Każdy zarejestrowany kod promocyjny weźmie udział w dwóch losowaniach:</p>
              <ul>
                <li>1) w jednym losowaniu nagród II-IV stopnia (przyporządkowanie do losowania zależne jest od daty
                  zarejestrowania kodu promocyjnego),</li>
                <li>2) oraz w losowaniu nagrody I stopnia.</li>
              </ul>
            </div>
          </div>
        </div>

        <h3 v-on:click="showFaqMainItem(4)">
          <span>UTRATA PRAWA DO NAGRODY</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>1. Czy mogę stracić prawo do odbioru nagrody i w jakich przypadkach?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Tak, laureat traci prawo do nagrody w&nbsp;każdym z&nbsp;poniższych przypadków:</p>
              <ul>
                <li>1) niedostarczenia do organizatora w wyznaczonym terminie wymaganych dokumentów oraz danych,</li>
                <li>2) przekazania oświadczenia zawierającego niekompletne bądź nieprawidłowe dane,</li>
                <li>3) dostarczenia lub przesłania w formie elektronicznej nieprawidłowego kuponu promocyjnego, bądź kopii
                  kuponu promocyjnego (w razie obowiązku doręczenia oryginału wersji papierowej), kuponu promocyjnego
                  zniszczonego lub nieczytelnego, z zastrzeżeniem, że laureat, którego kod promocyjny został wydany za
                  pośrednictwem adresu e-mail, nie dostarcza kuponu promocyjnego,</li>
                <li>4) przesłania nieprawidłowego numeru seryjnego transakcji tj. niezgodnego ze wzorem wskazanym w § 14
                  ust. 14 regulaminu lub innego niż ten z wygrywającego kuponu promocyjnego,</li>
                <li>5) poświadczenia nieprawdy w szczególności m.in. naruszenia postanowień § 8 regulaminu,</li>
                <li>6) lub niespełnienia pozostałych warunków regulaminu.</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>2. Co to jest numer seryjny transakcji</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Numer seryjny transakcji znajduje się na dole kuponu promocyjnego i składa się z 21 znaków według wzoru
                XXXX-XXXXXXXXX-XXXXXX, gdzie X to cyfra od 0 do 9.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>3. Co się dzieje z nagrodami, co do których utracono prawo do odbioru?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Nagrody niewydane i nieodebrane pozostają do dyspozycji TS.</p>
            </div>
          </div>
        </div>

        <h3 v-on:click="showFaqMainItem(5)">
          <span>REKLAMACJE</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>1. W jaki sposób i gdzie mogę złożyć reklamację?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Reklamacje należy zgłaszać od 24 listopada 2023 r. do 14 kwietnia 2024 r. (decyduje data wpływu reklamacji
                do organizatora) na piśmie na adres: Totalizator Sportowy Sp. z o.o., ul. Targowa 25, 03-728 Warszawa z
                dopiskiem: „Lotteria Świąteczna” – Reklamacja, lub przesłać na adres e-mail: <a
                  href="mailto:loterie@totalizator.pl">loterie@totalizator.pl</a> w
                tytule wpisując: „Lotteria Świąteczna” – Reklamacja. Reklamacja powinna zawierać: imię i nazwisko oraz
                adres zamieszkania, datę i miejsce zdarzenia, którego dotyczy roszczenie, nazwę loterii, oraz treść
                żądania. W przypadku zgłoszenia roszczenia za pomocą środków komunikacji elektronicznej uczestnik gry
                podaje również adres e-mail wskazany podczas zakładania konta. Reklamacje wniesione po terminie lub w
                innej formie niż wskazane w regulaminie albo niezwierające danych wskazanych w regulaminie nie będą
                rozpatrywane i pozostaną bez odpowiedzi.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>2. W jakim czasie od złożenia reklamacji mogę się spodziewać odpowiedzi?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Rozpatrzona reklamacja zawierająca odpowiedź Komisji Nadzoru Loterii przesyłana jest listem poleconym na
                adres uczestnika podany w treści reklamacji lub wiadomością e-mail wysyłaną z adresu:
                <a href="mailto:loterie@totalizator.pl">loterie@totalizator.pl</a> (w zależności od tego, w jaki sposób
                reklamacja wpłynęła) w terminie do 21 dni
                kalendarzowych od daty otrzymania reklamacji przez TS.
              </p>
            </div>
          </div>
        </div>

        <h3 v-on:click="showFaqMainItem(6)">
          <span>KONTAKT</span><i><img src="/assets/img/arrow.svg" /></i>
        </h3>
        <div class="faq-list">
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>1. Kto jest organizatorem loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>Organizatorem loterii jest Totalizator Sportowy Sp. z&nbsp;o. o., ul. Targowa 25, 03-728 Warszawa.</p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>2. Gdzie dostępny jest regulamin loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Regulamin loterii dostępny jest od 24 listopada 2023 r. w siedzibie Totalizatora Sportowego w dni robocze
                w godzinach od 9:00 do 16:00 oraz na stronie www.lotteriaswiateczna.pl od 27 listopada 2023 r. do 7 maja
                2024 r.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>3. Jaki jest adres strony internetowej loterii, na której mogę dokonać rejestracji konta i&nbsp;kodów
                promocyjnych?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Strona internetowa dedykowana loterii to www.lotteriaswiateczna.pl.
              </p>
            </div>
          </div>
          <div class="item">
            <h4 v-on:click="showFaqItem($event)">
              <span>4. Gdzie mogę kierować zapytania dotyczące loterii?</span>
              <i><img src="/assets/img/arrow.svg" /></i>
            </h4>
            <div class="faq-content">
              <p>
                Wszelkie zapytania dotyczące loterii można kierować na adres: <a
                  href="mailto:loteria.promocyjna@lotto.pl">loteria.promocyjna@lotto.pl</a> oraz na
                specjalną infolinię: 459-59-59-00 (koszt połączenia zgodny z taryfą operatora) czynną w dni robocze w
                godzinach 8.00 - 18.00.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="section-title" ref="contactRef">
        <span class="left"></span>
        <span class="text">Kontakt</span>
        <span class="right"></span>
      </div>
      <div class="page-content">
        <ul class="grid">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM41 13.75 24.8 24.35Q24.6 24.45 24.425 24.525Q24.25 24.6 24 24.6Q23.75 24.6 23.575 24.525Q23.4 24.45 23.2 24.35L7 13.75V37Q7 37 7 37Q7 37 7 37H41Q41 37 41 37Q41 37 41 37ZM24 21.9 40.8 11H7.25ZM7 13.75V14.1Q7 13.95 7 13.7Q7 13.45 7 13.15Q7 12.5 7 12.125Q7 11.75 7 12.15V11V12.15Q7 11.75 7 12.1Q7 12.45 7 13.1Q7 13.4 7 13.675Q7 13.95 7 14.1V13.75V37Q7 37 7 37Q7 37 7 37Q7 37 7 37Q7 37 7 37Z" />
            </svg>

            <h4>E-mail</h4>
            <p style="padding-top:20px">
              Skontaktuj się z nami pod adresem:
              <br />
              <a target="_blank" href="mailto:loteria.promocyjna@lotto.pl">loteria.promocyjna@lotto.pl</a>
            </p>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg">
              <path
                d="M23.5 42Q22.85 42 22.425 41.575Q22 41.15 22 40.5Q22 39.85 22.425 39.425Q22.85 39 23.5 39H39Q39 39 39 39Q39 39 39 39V23.8Q39 20.9 37.75 18.225Q36.5 15.55 34.4 13.5Q32.3 11.45 29.6 10.225Q26.9 9 24 9Q21.1 9 18.4 10.225Q15.7 11.45 13.6 13.5Q11.5 15.55 10.25 18.225Q9 20.9 9 23.8V35Q9 35.4 8.725 35.7Q8.45 36 8 36Q6.35 36 5.175 34.825Q4 33.65 4 32V28Q4 26.85 4.55 25.975Q5.1 25.1 6 24.55L6.15 21.9Q6.6 18.25 8.225 15.3Q9.85 12.35 12.275 10.3Q14.7 8.25 17.725 7.125Q20.75 6 24 6Q27.3 6 30.325 7.125Q33.35 8.25 35.75 10.325Q38.15 12.4 39.775 15.325Q41.4 18.25 41.85 21.85L42 24.45Q42.9 24.9 43.45 25.775Q44 26.65 44 27.7V32.3Q44 33.4 43.45 34.25Q42.9 35.1 42 35.55V39Q42 40.25 41.125 41.125Q40.25 42 39 42ZM18 27.5Q17.4 27.5 16.95 27.05Q16.5 26.6 16.5 25.95Q16.5 25.35 16.95 24.925Q17.4 24.5 18.05 24.5Q18.65 24.5 19.075 24.925Q19.5 25.35 19.5 26Q19.5 26.6 19.075 27.05Q18.65 27.5 18 27.5ZM30 27.5Q29.4 27.5 28.95 27.05Q28.5 26.6 28.5 25.95Q28.5 25.35 28.95 24.925Q29.4 24.5 30.05 24.5Q30.65 24.5 31.075 24.925Q31.5 25.35 31.5 26Q31.5 26.6 31.075 27.05Q30.65 27.5 30 27.5ZM12.05 24.9Q11.85 21.95 12.875 19.55Q13.9 17.15 15.625 15.475Q17.35 13.8 19.6 12.9Q21.85 12 24.1 12Q28.65 12 31.75 14.875Q34.85 17.75 35.55 22.05Q30.85 22 27.275 19.525Q23.7 17.05 21.75 13.1Q20.95 17.15 18.375 20.275Q15.8 23.4 12.05 24.9Z" />
            </svg>

            <h4>Infolinia 459 59 59 00</h4>
            <p style="padding-top:20px">
              Nasi konsultanci są dostępni od poniedziałku do piątku (z&nbsp;wyłączeniem dni ustawowo wolnych od pracy)
              w&nbsp;godz. 8:00-18:00.<br />
              Koszt połączenia zgodny z taryfą operatora.
            </p>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 42Q5.75 42 4.875 41.125Q4 40.25 4 39V9Q4 7.75 4.875 6.875Q5.75 6 7 6H20.5Q21.75 6 22.625 6.875Q23.5 7.75 23.5 9V14.25H41Q42.25 14.25 43.125 15.125Q44 16 44 17.25V39Q44 40.25 43.125 41.125Q42.25 42 41 42ZM7 39H12.25V33.75H7ZM7 30.75H12.25V25.5H7ZM7 22.5H12.25V17.25H7ZM7 14.25H12.25V9H7ZM15.25 39H20.5V33.75H15.25ZM15.25 30.75H20.5V25.5H15.25ZM15.25 22.5H20.5V17.25H15.25ZM15.25 14.25H20.5V9H15.25ZM23.5 39H41V17.25H23.5V22.5H27.5V25.5H23.5V30.75H27.5V33.75H23.5ZM32.75 25.5V22.5H35.75V25.5ZM32.75 33.75V30.75H35.75V33.75Z" />
            </svg>
            <h4>Napisz na adres</h4>
            <p style="padding-top:20px">
              Totalizator Sportowy Sp. z o.o.
              <br />ul. Targowa 25, 03- 728 Warszawa
              <br />Z dopiskiem: "Lotteria Świąteczna"
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="lotto-fix">
      <a href="https://lotto.pl">
        <img src="assets/img/lotto-online.png" />
      </a>
    </div>
    <Notification />
  </div>
</template>
<script>

import axios from "axios";
import drawItem from "@/components/public/draw";

export default {
  name: "Home",
  data: () => ({
    lastScrollPosition: 0,
    scrollValue: 0,
    fRow: false,
    sRow: false,
    searchTerm: "",
    draw: [],
    currentFaq: -1,
    drawFiltered: [],
  }),
  mounted() {
    window.scrollTo(0, 0);
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll);
    this.showNotification();
    this.$root.$on('homeNavClick', (item) => {
      if (item != 'blank') {
        this.scrollToElement(item);
      }
    })
  },
  created() {
    this.getWinners();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    scrollToTop(e) {
      e.preventDefault();
      if (this.$route.path === "/") {
        window.scrollTo(0, 0);
      }
    },
    scrollToElement(item) {
      if (this.$refs['' + item + ''] != undefined) {
        this.$refs['' + item + ''].scrollIntoView({ block: 'start', behavior: "smooth" });
      }
    },
    showNotification() {
      let data = {
        state: true,
        messageHtml: '<p style="text-align:center; font-size:15px; line-height: 21px; padding:0 10px;">Szanowni Państwo, informujemy, że okres sprzedaży produktów promocyjnych, rejestracji kont, rejestracji kodów promocyjnych oraz wymiana talonów uprawniających do odbioru zakładu Lotto o wartości 3 zł zostały zakończone.<br />Dziękujemy za udział w loterii i gratulujemy zwycięzcom.<br /></p>',
        type: 'info'
      };
      this.$store.commit('setNotification', data);
    },
    getWinners() {
      axios
        .get("/winnersList")
        .then((response) => {
          this.draw = response.data;
          this.drawFiltered = response.data;
        })
        .catch((e) => { });
    },
    showItem(event) {
      var parent = event.currentTarget.parentNode;
      if (
        (" " + parent.className + " ")
          .replace(/[\n\t]/g, " ")
          .indexOf(" open ") > -1
      ) {
        parent.classList.remove("open");
      } else {
        parent.classList.add("open");
      }
    },
    showFaqMainItem(itemIndex) {
      let faqElements = document.getElementsByClassName("faq-list");

      faqElements.forEach((element) => {
        element.classList.remove('opened');
      });

      if (this.currentFaq != itemIndex) {
        let clickedElement = document.getElementsByClassName("faq-list")[itemIndex];
        clickedElement.classList.add('opened');
        this.currentFaq = itemIndex;
      }
      else {
        this.currentFaq = -1;
      }
    },
    showFaqItem(event) {
      var parent = event.currentTarget.parentNode;
      if (
        (" " + parent.className + " ")
          .replace(/[\n\t]/g, " ")
          .indexOf(" open ") > -1
      ) {
        parent.classList.remove("open");
      } else {
        parent.classList.add("open");
      }
    },
    filterWinners(e) {
      if (e != undefined) {
        e.preventDefault();
      }
      let result = this.draw;
      // if (!this.searchTerm) return result;
      const filterValue = this.searchTerm.toLowerCase();
      const filter = (item) =>
        item.code.toLowerCase().includes(filterValue) ||
        item.status.toLowerCase().includes(filterValue);
      this.drawFiltered = result.filter(filter);

      this.$gtag.event('kod_promocyjny_wyszukiwanie', {
        'event_category': 'general',
        'event_label': 'kod_promocyjny_wyszukiwanie',
        'value': filterValue
      });
    }
  },
  watch: {
    searchTerm(newValue) {
      if (newValue == "") {
        this.filterWinners();
      }
    }
  },
  components: {
    drawItem
  }
};
</script>