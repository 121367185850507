<template>
  <div class="draw__item" :class="{ active: currentDraw.length > 0 }">
    <div class="draw__item__mobile" v-for="(item, index) in currentDraw" :key="index">
      <strong>Nagroda:</strong> {{ prizeEnum[item.priceRank - 1] }}<br />
      <strong>Wylosowany kod promocyjny:</strong> {{ item.code }}<br />
      <span v-if="!item.reserve">Laureat główny</span>
      <span v-if="item.reserve">Laureat rezerwowy</span>
      <strong>Dane zwycięzcy:</strong> {{ item.name }}
    </div>

    <table class="table" v-if="draw.length > 0">
      <thead>
        <tr>
          <td><strong>Nagroda</strong></td>
          <td><strong>Wylosowany kod promocyjny</strong></td>
          <td><strong>Dane zwycięzcy</strong></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in currentDraw" :key="index">
          <!-- <td>
            {{ prizeEnum[item.priceRank - 1] }}
          </td> -->
          <td>
            <span v-if="item.price == 'KASA 100000'">100 000 zł</span>
            <span v-if="item.price == 'KASA 100000_REZERWA'">100 000 zł</span>

            <span v-if="item.price == 'KASA 25000'">25 000 zł</span>
            <span v-if="item.price == 'KASA 25000_REZERWA'">25 000 zł</span>

            <span v-if="item.price == 'KASA 5000'">5 000 zł</span>
            <span v-if="item.price == 'KASA 5000_REZERWA'">5 000 zł</span>

            <span v-if="item.price == 'KASA 500'">500 zł</span>
          </td>
          <td>{{ item.code }}</td>
          <td>{{ item.name }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="no-items">Dane zwycięzców publikowane są po zakończeniu procedury weryfikacji zgłoszeń.</div>
  </div>
</template>

<script>
export default {
  name: "drawItem",
  data: () => ({
    prizeEnum: [
      "100 000 PLN",
      "25 000 PLN",
      "5 000 PLN",
      "500 PLN"
    ]
  }),
  props: {
    draw: {
      type: Array,
      required: true,
      default: []
    },
    drawName: {
      type: String,
      required: true,
      default: ""
    }
  },
  mounted() { },
  computed: {
    currentDraw() {
      let arr = [];
      this.draw.forEach(item => {
        if (item.drawName === this.drawName) {
          arr.push(item);
        }
      });

      arr = arr.sort((a, b) => {
        if (a.priceRank === b.priceRank) {
          return parseInt(a.priceInstanceLP) < parseInt(b.priceInstanceLP) ? -1 : 1
        } else {
          return parseInt(a.priceRank) < parseInt(b.priceRank) ? -1 : 1
        }
      });

      arr = arr.sort((a, b) => {
        return parseInt(a.priceRank) < parseInt(b.priceRank) ? -1 : 1
      });
      return arr;
    }
  },
  filters: {
    codeDisplay: function (value) {
      var splitted = value.split("");
      var result = `${splitted.splice(0, 4).join('')}-${splitted.splice(0, 3).join('')}-${splitted.splice(0, 4).join('')}`;
      return result;
    }
  }
};
</script>
