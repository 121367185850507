<template>
  <div class="page-title" style="padding-top:200px;">
      <h1 v-html="title"></h1>
  </div>
</template>
<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>