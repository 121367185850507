import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'App', component: Home },
  { path: '/polityka-prywatnosci', name: 'politykaprywatnosci', component: () => import(/* webpackChunkName: "public" */ '../views/public/polityka-prywatnosci.vue') },
  { path: '/klauzula-informacyjna', name: 'klauzulainformacyjna', component: () => import(/* webpackChunkName: "public" */ '../views/public/klauzula-informacyjna.vue') },
  { path: '/kupon', name: 'kupon', component: () => import(/* webpackChunkName: "public" */ '../views/public/kupon.vue') },

  { path: '/przypomnienie-hasla', name: 'przypomnieniehasla', component: () => import(/* webpackChunkName: "public" */ '../views/public/przypomnienie-hasla.vue') },
  // { path: '/aktywacja-konta/:hash', name: 'aktywacja', component: () => import(/* webpackChunkName: "public" */ '../views/public/aktywacja.vue') },
  { path: '/resetuj-haslo/:hash', name: 'resethasla', component: () => import(/* webpackChunkName: "public" */ '../views/public/resethasla.vue') },
  // { path: '/rejestracja', name: 'rejestracja', component: () => import(/* webpackChunkName: "public" */ '../views/public/rejestracja.vue') },
  // { path: '/rejestracja/:hash', name: 'rejestracja-reklamacja', component: () => import(/* webpackChunkName: "public" */ '../views/public/rejestracja-reklamacja.vue') },
  { path: '/logowanie', name: 'logowanie', component: () => import(/* webpackChunkName: "public" */ '../views/public/logowanie.vue') },

  // Przykład numeru seryjnego z kuponu
  { path: '/kupon-numer-seryjny', name: 'kupon-numer-seryjny', component: () => import(/* webpackChunkName: "public" */ '../views/public/numer-seryjny.vue') },
  // secured
  { path: '/profil', name: 'profil', component: () => import(/* webpackChunkName: "profile" */ '../views/secured/profil.vue'), meta: { requiresAuth: true } },
  //{ path: '/oswiadczenie-I-stopnia', name: 'oswiadczenie-I-stopnia', component: () => import(/* webpackChunkName: "public" */ '../views/secured/oswiadczenie1.vue'), meta: { requiresAuth: true } },
  //{ path: '/oswiadczenie-II-stopnia', name: 'oswiadczenie-II-stopnia', component: () => import(/* webpackChunkName: "public" */ '../views/secured/oswiadczenie2.vue'), meta: { requiresAuth: true } },
  //{ path: '/oswiadczenie-III-stopnia', name: 'oswiadczenie-III-stopnia', component: () => import(/* webpackChunkName: "public" */ '../views/secured/oswiadczenie3.vue'), meta: { requiresAuth: true } },
  { path: '/oswiadczenie-IV-stopnia/:hash', name: 'oswiadczenie-IV-stopnia', component: () => import(/* webpackChunkName: "public" */ '../views/secured/oswiadczenie4.vue'), meta: { requiresAuth: true } },
  { path: '*', component: Home }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const sessionKey = sessionStorage.getItem("sessionKey");
  next();
  if (to.path === "/") {
    next();
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionKey) {
      next({
        path: '/logowanie',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
