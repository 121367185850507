<template>
    <header>
        <router-link tag="div" to="/" class="logo-lotto"></router-link>
        <nav :class="{ 'show-nav': showNav }">
            <li><router-link v-on:click.native="mobNav('prizesRef')" to="/nagrody">Nagrody</router-link></li>
            <li><router-link v-on:click.native="mobNav('rulesRef')" to="/zasady">Zasady</router-link></li>
            <!-- <li><router-link v-on:click.native="mobNav('lottoOnlineRef')" to="/dla-graczy-lotto">Zasady dla klientów
                    lotto.pl/Aplikacji LOTTO</router-link></li> -->
            <li><router-link v-on:click.native="mobNav('drawDatesRef')" to="/terminy-losowan">Terminy losowań</router-link>
            </li>
            <li><router-link v-on:click.native="mobNav('winersRef')" to="/lista-zwyciezcow">Lista Zwycięzców</router-link>
            </li>
            <li><router-link v-on:click.native="mobNav('faqRef')" to="/faq">FAQ</router-link></li>
            <li><router-link v-on:click.native="mobNav('contactRef')" to="/kontakt">Kontakt</router-link></li>
            <li><a target="_blank" href="/assets/pdf/Regulamin-Lotterii-Swiatecznej.pdf">Regulamin</a></li>
            <li>
                <router-link v-on:click.native="mobNav('blank')" class="btn" v-if="token.length === 0"
                    to="/logowanie">Zaloguj</router-link>
                <!-- <router-link v-on:click.native="mobNav('blank')" class="btn" v-if="token.length === 0"
                    to="/rejestracja">Zarejestruj</router-link> -->
                <span v-if="token.length > 0">
                    <router-link v-on:click.native="mobNav('blank')" class="btn" to="/profil">Twoje konto</router-link>
                    <a v-on:click="Logout($event)" class="btn" href="#">Wyloguj</a>
                </span>
            </li>
        </nav>
        <div class="mob-nav" v-on:click="mobNav()"><span></span> <span></span> <span></span></div>
    </header>
</template>

<script>
export default {
    name: "HeaderTemplate",
    data: () => ({
        token: "",
        showNav: false
    }),
    mounted() {
        this.checkSession();
    },
    methods: {
        mobNav(ref) {
            this.showNav = !this.showNav;
            this.$root.$emit('homeNavClick', ref);
        },
        checkSession() {
            if (sessionStorage.getItem("sessionKey") !== null) {
                this.token = sessionStorage.getItem("sessionKey");
            }
        },
        Logout() {
            sessionStorage.setItem("sessionKey", "");
            sessionStorage.setItem("sessionData", "");
            this.$router.push({ name: "App" });
            this.mobNav();
        }
    },
    watch: {
        $route: function () {
            this.checkSession();
        }
    }
};
</script>