<template>
	<footer>
		<div class="container">
			<img class="responsible-game" src="/assets/img/responsible-game.svg" />
			<p>
				Okres sprzedaży promocyjnej trwa od 27 listopada do 31 grudnia 2023 r.<br />
				Liczba talonów na zakład Lotto jest ograniczona i wynosi 1 000 000 sztuk.<br />
				Talony uprawniające do odbioru zakładu Lotto dostępne wyłącznie w punktach sprzedaży LOTTO.<br />
				Organizator loterii: Totalizator Sportowy Sp. z o.o., ul. Targowa 25, 03-728 Warszawa.
			</p>
			<p>
				<router-link to="/polityka-prywatnosci">Polityka prywatności</router-link> | <router-link
					to="/klauzula-informacyjna">Klauzula informacyjna</router-link>
			</p>
		</div>
	</footer>
</template>

<script>
export default {
	name: "footerHomeTemplate",
	data: () => ({}),
	methods: {
		scrollToTop(e) {
			if (this.$route.path === "/") {
				window.scrollTo(0, 0);
			}
		}
	}
};
</script>
<style>
footer .responsible-game {
	margin: 20px 40px;
	position: absolute;
	left: 0;
	width: 40px;
	bottom: 20px;
}
</style>