import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loader: false,
    notification: {
      state: false,
      message: '',
      messageHtml: '',
      type: 'succcess'
    },
    lottoCodes: {
      codes: '',
      pageNumber: 1,
      take: 10,
      hasNextPage: false,
      hasPrevPage: false,
      count: 0
    },
    ilottoCodes: {
      codes: '',
      take: 10,
      pageNumber: 1,
      hasNextPage: false,
      hasPrevPage: false,
      count: 0
    },
    statement: {
      name: '',
      email: '',
      code: ''
    }
  },
  mutations: {
    setNotification(state, data) {
      state.notification.message = data.message;
      state.notification.messageHtml = data.messageHtml;
      state.notification.state = data.state;
      state.notification.type = data.status;
    },
    closeNotification(state) {
      state.notification.message = '';
      state.notification.messageHtml = '';
      state.notification.state = false;
      state.notification.type = 'succcess';
    },
    loader(state) {
      state.loader = !state.loader;
    },
    lottoCodes(state, data) {
      state.lottoCodes.codes = data.items;
      state.lottoCodes.hasNextPage = data.hasNextPage;
      state.lottoCodes.count = data.count;
    },
    setLottoPage(state, data) {
      state.lottoCodes.pageNumber = data.pageNumber;
      state.lottoCodes.take = data.take;
      state.lottoCodes.hasPrevPage = data.hasPrevPage;
    },
    iLottoCodes(state, data) {
      state.ilottoCodes.codes = data.items;
      state.ilottoCodes.hasNextPage = data.hasNextPage;
      state.ilottoCodes.count = data.count;
    },
    setILottoPage(state, data) {
      state.ilottoCodes.pageNumber = data.pageNumber;
      state.ilottoCodes.take = data.take;
      state.ilottoCodes.hasPrevPage = data.hasPrevPage;
    },
  },
  actions: {
    getLottoCodes({ commit }) {
      let data = {
        token: sessionStorage.getItem("sessionKey"),
        skip: this.state.lottoCodes.pageNumber - 1,
        take: this.state.lottoCodes.take
      }
      axios.post('GetCodes', data).then((response) => {
        commit('lottoCodes', response.data);
      })
    },
    getILottoCodes({ commit }) {
      let data = {
        token: sessionStorage.getItem("sessionKey"),
        skip: this.state.ilottoCodes.pageNumber - 1,
        take: this.state.ilottoCodes.take
      }
      axios.post('getICodes', data).then((response) => {
        commit('iLottoCodes', response.data);
      })
    },
  },
  modules: {
  }
})
