<template>
  <div>
    <div class="notification" v-bind:class="{ '--show': $store.state.notification.state }">
      <div class="__content">
        <div>
          <div class="__placeholder">
            <div class="__close" v-on:click="close()"></div>
            <div style="margin:20px 0 0 0;">
              <h3>
                <img v-if="$store.state.notification.status === 'success'" class="success-icon"
                  src="/assets/img/success.svg" />
                <img v-if="$store.state.notification.status === 'error'" class="error-icon" src="/assets/img/error.svg" />
              </h3>
              <p v-if="$store.state.notification.message" style="padding: 20px;">{{ $store.state.notification.message }}
              </p>
              <div v-html="$store.state.notification.messageHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification__overlay" v-on:click="close()"></div>
  </div>
</template>
<script>
export default {
  name: "notification",
  methods: {
    close() {
      this.$store.commit('closeNotification');
    }
  }
};
</script>
<style>
.notification {
  backface-visibility: hidden;
  height: auto;
  left: 50%;
  max-width: 90vw;
  max-height: 90vw;
  min-width: 320px;
  position: fixed;
  top: 40%;
  -webkit-transform: translate(-50%, -51%);
  -khtml-transform: translate(-50%, -51%);
  -moz-transform: translate(-50%, -51%);
  -ms-transform: translate(-50%, -51%);
  -o-transform: translate(-50%, -51%);
  transform: translate(-50%, -51%);
  visibility: hidden;
  z-index: 110000;
}

.--show {
  visibility: visible;
}

.notification__overlay {
  background: rgba(0, 0, 0, 0.55) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 109990;
}

.--show~.notification__overlay {
  opacity: 1;
  visibility: visible;
}

.__content {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s ease 0s;
}

.__placeholder {
  max-width: 100%;
  padding: 20px;
  color: #1a1a1a;
  font-size: 1.2em;
  line-height: 1.4em;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  margin: 20px;
  padding: 20px;
  position: relative;
  border: 6px solid #dbc6acb0;
}

.__placeholder h4 {
  font-size: 1.4em;
  margin: 0 0 20px 0;
}

.__placeholder img {
  height: 48px;
}

.--show .__content {
  opacity: 1;
  transform: scale(1);
}

.__close {
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 32px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
  z-index: 100;
  background: #dbc6acb0;
  border-radius: 50%;
}

.__close::before,
.__close::after {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 4px;
  left: 7px;
  margin-top: -1px;
  position: absolute;
  top: 47%;
  border-radius: 12px;
  width: 60%;
}

.__close::after {
  transform: rotate(45deg);
}

.__close::before {
  transform: rotate(-45deg);
}
</style>