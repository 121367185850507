import Vue from 'vue'
import App from './App.vue'
import 'es6-promise/auto'
import router from './router'
import store from './store'
import VueAppInsights from 'vue-application-insights'
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false

require('./assets/css/loteriada.css')

const devInsightsKey = 'd007d721-af20-40fe-b6f8-a3aa84c4715b';
Vue.use(VueAppInsights, {
  id: devInsightsKey
});

Vue.use(VueGtag, {
  config: {
    id: 353829243
  }
});

import axios from 'axios'
axios.defaults.baseURL = 'https://api.lotteriaswiateczna.pl/api/';
// axios.defaults.baseURL = 'https://loteriaswiateczna-dev-func.azurewebsites.net/api/';
axios.defaults.timeout = 60000;

axios.interceptors.request.use(function (config) {
  return config;
});

axios.interceptors.response.use(response => {
  return response;
}, function (err) {
  if (err.response.status == 400) {
    store.state.notification.state = true;
    store.state.notification.message = err.response.data.message;
    store.state.notification.status = 'error';
    if (err.response.data.logout) {
      sessionStorage.setItem("sessionKey", "");
      sessionStorage.setItem("sessionData", "");
      router.push({ name: "App" });
    }
  }

  return Promise.reject(err);
});

import VueMask from 'v-mask'
Vue.use(VueMask);

// Components
import Notification from '@/components/layout/notification'
import PageTitle from '@/components/public/pagetitle'
import Legal from '@/components/public/legal'
import Button from '@/components/layout/button'
import Loader from '@/components/layout/loader'

Vue.component('Notification', Notification);
Vue.component('PageTitle', PageTitle);
Vue.component('Legal', Legal);
Vue.component('Button', Button);
Vue.component('Loader', Loader);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
